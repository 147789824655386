import * as index from './index';
index.Chart.Inject(index.LineSeries, index.ScatterSeries, index.ColumnSeries, index.SplineSeries, index.SplineAreaSeries, index.StripLine, index.AreaSeries, index.ScrollBar, index.StepLineSeries, index.StepAreaSeries, index.StackingColumnSeries, index.StackingLineSeries, index.StackingAreaSeries, index.StackingStepAreaSeries, index.BarSeries, index.StackingBarSeries, index.RangeColumnSeries, index.BubbleSeries, index.Tooltip, index.Crosshair, index.Category, index.DateTime, index.Logarithmic, index.Legend, index.Zoom, index.DataLabel, index.Selection, index.ChartAnnotation, index.HiloSeries, index.HiloOpenCloseSeries, index.WaterfallSeries, index.RangeAreaSeries, index.RangeStepAreaSeries, index.SplineRangeAreaSeries, index.CandleSeries, index.PolarSeries, index.RadarSeries, index.SmaIndicator, index.TmaIndicator, index.EmaIndicator, index.AccumulationDistributionIndicator, index.MacdIndicator, index.AtrIndicator, index.RsiIndicator, index.MomentumIndicator, index.StochasticIndicator, index.BollingerBands, index.BoxAndWhiskerSeries, index.HistogramSeries, index.ErrorBar, index.Trendlines, index.DateTimeCategory, index.MultiColoredLineSeries, index.MultiColoredAreaSeries, index.MultiLevelLabel, index.ParetoSeries, index.Export, index.DataEditing, index.Highlight);
index.AccumulationChart.Inject(index.PieSeries, index.FunnelSeries, index.PyramidSeries, index.AccumulationTooltip, index.AccumulationLegend, index.AccumulationSelection, index.AccumulationDataLabel, index.AccumulationAnnotation, index.Export);
index.RangeNavigator.Inject(index.RangeTooltip, index.PeriodSelector, index.AreaSeries, index.StepLineSeries, index.DateTime, index.Logarithmic, index.Export, index.DateTimeCategory);
index.Sparkline.Inject(index.SparklineTooltip);
index.Smithchart.Inject(index.SmithchartLegend, index.TooltipRender);
index.StockChart.Inject(index.LineSeries, index.ColumnSeries, index.SplineSeries, index.SplineAreaSeries, index.StripLine, index.AreaSeries, index.RangeAreaSeries, index.Tooltip, index.Crosshair, index.DateTime, index.Zoom, index.DataLabel, index.Selection, index.ChartAnnotation, index.HiloSeries, index.HiloOpenCloseSeries, index.CandleSeries, index.SmaIndicator, index.TmaIndicator, index.EmaIndicator, index.AccumulationDistributionIndicator, index.MacdIndicator, index.AtrIndicator, index.RsiIndicator, index.MomentumIndicator, index.StochasticIndicator, index.BollingerBands, index.Trendlines, index.RangeTooltip, index.Export, index.StockLegend, index.DateTimeCategory);
index.BulletChart.Inject(index.BulletTooltip, index.BulletChartLegend);
index.Chart3D.Inject(index.ColumnSeries3D, index.StackingColumnSeries3D, index.BarSeries3D, index.StackingBarSeries3D, index.Category3D, index.DateTime3D, index.DateTimeCategory3D, index.Logarithmic3D, index.Tooltip3D, index.Legend3D, index.DataLabel3D, index.Selection3D, index.Export3D, index.Highlight3D);
export * from './index';
